// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lawyers-js": () => import("./../../../src/pages/lawyers.js" /* webpackChunkName: "component---src-pages-lawyers-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-remuneration-policy-js": () => import("./../../../src/pages/remuneration-policy.js" /* webpackChunkName: "component---src-pages-remuneration-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-category-author-js": () => import("./../../../src/templates/category-author.js" /* webpackChunkName: "component---src-templates-category-author-js" */),
  "component---src-templates-category-services-js": () => import("./../../../src/templates/category-services.js" /* webpackChunkName: "component---src-templates-category-services-js" */),
  "component---src-templates-kmagazin-js": () => import("./../../../src/templates/kmagazin.js" /* webpackChunkName: "component---src-templates-kmagazin-js" */),
  "component---src-templates-lawyer-js": () => import("./../../../src/templates/lawyer.js" /* webpackChunkName: "component---src-templates-lawyer-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-services-subpage-js": () => import("./../../../src/templates/services_subpage.js" /* webpackChunkName: "component---src-templates-services-subpage-js" */)
}

