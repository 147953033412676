module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"defaultLanguage":"de","i18nextOptions":{"keySeparator":".","nsSeparator":":","defaultNs":"translation","fallbackLng":"de","whitelist":["en","de"],"returnEmptyString":false,"returnNull":false,"wait":true,"detection":{"order":["querystring","localStorage","cookie","path","navigator"],"lookupQuerystring":"lang","lookupCookie":"CC","lookupLocalStorage":"lang","lookupFromPathIndex":0,"caches":["localStorage"],"checkWhitelist":true},"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Korten","short_name":"Korten","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/favicons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8eca4ae44f4a859791cbeacb5b955de9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"crumbLabelUpdates":[{"pathname":"/en","crumbLabel":"Home"},{"pathname":"/blogs","crumbLabel":"News"},{"pathname":"/en/blogs","crumbLabel":"News"},{"pathname":"/en/category-author","crumbLabel":"News"},{"pathname":"/category-author","crumbLabel":"News"},{"pathname":"/category-services","crumbLabel":"News"},{"pathname":"/en/category-services","crumbLabel":"News"},{"pathname":"/lawyers","crumbLabel":"Team"},{"pathname":"/en/lawyers","crumbLabel":"Lawyers"},{"pathname":"/services","crumbLabel":"Rechtsbereiche"},{"pathname":"/en/services","crumbLabel":"Services"},{"pathname":"/en/kmagazin","crumbLabel":"K+ Magazin"},{"pathname":"/kmagazin","crumbLabel":"K+ Magazin"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
